/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValueGetterParams } from '@ag-grid-community/core';
import { FieldType, Precision, SelectItem } from '@do/common-interfaces';

import { FilterConfig } from './filter-config';

// Proprietà 'text'-align' del contenuto della colonna
export enum ContentAlignmentEnum {
  Left,
  Center,
  Right,
}

export interface EntityListField extends FilterConfig {
  contentAlignment?: ContentAlignmentEnum;
  elementValues?: SelectItem[];
  exportHideColumn?: boolean;
  fieldType?: FieldType;
  hideColumn?: boolean;
  hideFilter?: boolean;
  initialHideColumn?: boolean;
  minWidth?: number;
  maxWidth?: number;
  valueGetter?: (params: ValueGetterParams<any>) => any;
  precision?: Precision;
}
