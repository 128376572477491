export enum FieldType {
  text,
  date,
  time,
  datetime,
  decimal,
  integer,
  percent,
  currency,
  boolean,
  enum,
  document,
  image,
  json,
  year,
}
