import { v4 as uuidv4 } from 'uuid';

import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';

import { LoaderState } from './loader.state';

export function withStoreMethods() {
  return signalStoreFeature(
    { state: type<LoaderState>() },
    withMethods((state) => ({
      showLoader(customLoadingMessages?: string[] | undefined) {
        // const isActionAlreadyInProgress = state
        //   .actionsInProgress()
        //   .filter((currentAction) => currentAction === actionType).length;

        // If the action in already in progress and is registered
        // we don't modify the state
        // if (!isActionAlreadyInProgress) {
        // Adding the action type in our actionsInProgress array

        const actionId = uuidv4();
        const newActionsInProgress = [...state.actionsInProgress(), actionId];

        // reducer
        patchState(state, {
          active: newActionsInProgress.length,
          actionsInProgress: newActionsInProgress,
          customLoadingMessages,
        });

        // if (
        //   window.document.activeElement &&
        //   window.document.activeElement instanceof HTMLElement
        // ) {
        //   window.document.activeElement.blur();
        // }

        return actionId;
        // }
      },
      hideLoader(actionId: string) {
        // We remove trigger action from actionsInProgress array
        const newActionsInProgress = actionId
          ? state
              .actionsInProgress()
              .filter((currentAction) => currentAction !== actionId)
          : state.actionsInProgress();

        // reducer
        patchState(state, {
          actionsInProgress: newActionsInProgress,
          active: state.active() > 0 ? newActionsInProgress.length : 0,
          customLoadingMessages: [],
        });
      },
      forceHideLoader() {
        const newActionsInProgress: string[] = [];

        // reducer
        patchState(state, {
          actionsInProgress: newActionsInProgress,
          active: 0,
          customLoadingMessages: [],
        });
      },
    }))
  );
}
