/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { IconButtonComponent } from '../core/icon-button/icon-button.component';

@Component({
  selector: 'do-form-field-wrapper',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 500;
      }

      .feedback {
        font-size: 12px;
      }

      .hint {
        opacity: 0.6;
      }
    `,
  ],
  template: `
    <ng-template #tipContent>{{ tooltip }}</ng-template>
    <label
      class="text-dark d-flex align-items-center mb-1 fs-0875 fw-semibold"
      *ngIf="label"
    >
      <span>{{ label }}</span>

      <do-icon-button
        class="ms-2 tip"
        icon="info"
        cssClasses="text-primary"
        [ngbTooltip]="tipContent"
        iconFontSize="16px"
        *ngIf="tooltip.length > 0"
      ></do-icon-button
    ></label>
    <div [class.is-invalid]="showHint || shouldShowError">
      <ng-content></ng-content>
    </div>

    <ng-container *ngIf="showHint || shouldShowError">
      <div
        *ngFor="let err of errors"
        class="invalid-feedback"
        [class.invalid-feedback]="shouldShowError"
        [class.hint]="!shouldShowError"
      >
        {{ err }}
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconButtonComponent, NgbTooltip],
})
export class FormFieldWrapperComponent {
  @Input()
  label = '';

  @Input()
  tooltip = '';

  @Input()
  errors: string[] = [];

  @Input()
  shouldShowError = false;

  @Input()
  showHint = false;
}
