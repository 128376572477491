import { InjectionToken } from '@angular/core';

export interface ConfirmServiceInterface {
  confirm(
    message?: string,
    title?: string,
    cancelText?: string,
    okText?: string
  ): Promise<boolean>;

  deleteConfirm(): Promise<boolean>;
}

export const CONFIRM_SERVICE_TOKEN =
  new InjectionToken<ConfirmServiceInterface>('ConfirmService');
