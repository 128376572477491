export enum Permission {
  Access = 'access',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  SendMasterCommand = 'can_send_master_command',
  SendMasterCommandAdvanced = 'can_send_advanced_master_command',
  SystemAdministration = 'system_administration',
}
