import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class BaseFormFieldComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input()
  label = '';

  errors = input([] as string[], {});

  shouldShowErrorExternalCondition = input(false, { alias: 'shouldShowError' });

  shouldShowError = computed(
    () => this.errors().length > 0 && this.shouldShowErrorExternalCondition()
  );

  @Input()
  readOnly = false;

  @Input()
  isValid = true;

  @Input()
  tooltip = '';

  @Input()
  showHint = false;

  internalValue: any = null;

  formControl = new FormControl();

  protected ngUnsubscribe = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (value: any) => {};
  onTouched = () => {};

  onChanged(v: any) {
    this.internalValue = v;
    this.propagateChange(v);
    this.onTouched();
  }
  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((v: any) => {
        // console.log(
        //   `Valori: ${JSON.stringify(v)} # ${JSON.stringify(
        //     this.internalValue
        //   )} -> ${JSON.stringify(v) !== JSON.stringify(this.internalValue)}`
        // );
        if (JSON.stringify(v) !== JSON.stringify(this.internalValue))
          this.onChanged(v);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  writeValue(obj: any) {
    this.internalValue = obj;
    this.formControl.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.formControl.disable();
    else this.formControl.enable();
  }
}
