export enum FilterOperator {
  contains = 0,
  notContains = 1,
  equals = 2,
  notEqual = 3,
  startsWith = 4,
  endsWith = 5,
  greaterThan = 6,
  lessThan = 7,
  greaterThanOrEqual = 8,
  lessThanOrEqual = 9,
  inRange = 10,
  // blankOrEmpty,
  notBlank = 11,
  blank = 12,
  in = 13,
}
