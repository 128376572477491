import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  template: `
    <button
      [type]="type"
      class="btn d-flex align-items-center position-relative"
      [class.icon-only]="text === ''"
      [class]="cssClasses"
      [title]="text"
      [disabled]="disabled"
      (click)="clicked.emit()"
    >
      <span
        class="material-symbols-outlined"
        [class.me-1]="!!text"
        *ngIf="icon"
      >
        {{ icon }}
      </span>
      <span [title]="text" class="text-nowrap">{{ text }}</span>
      <span
        *ngIf="showBadge"
        class="position-absolute top-0 start-0 translate-middle badge rounded-pill"
        [class]="badgeClass"
      >
        {{ badgeContent }}
      </span>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
  selector: 'do-button',
  imports: [CommonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  cssClasses = '';

  @Input()
  type = 'button';

  @Input()
  icon = '';

  @Input()
  disabled? = false;

  @Input()
  text = '';

  @Input()
  badgeContent?: any;

  @Input()
  badgeClass = 'bg-danger';

  @Output()
  clicked = new EventEmitter<void>();

  get showBadge() {
    return !!this.badgeContent;
  }
}
