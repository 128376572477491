/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from 'lodash';

import { ColDef, ColumnState } from '@ag-grid-community/core';
import {
  FieldConfig,
  FieldType,
  FilterOperator,
  FilterType,
  FilterValue,
} from '@do/common-interfaces';

import { AgGridFilter, AgGridFilterModel } from './aggrid-models';
import { ContentAlignmentEnum, EntityListField } from './entity-list-field';

export function mapToFilterModel(filters: FilterValue[]) {
  const filterModel: AgGridFilterModel = {};
  return filters.reduce((res, f) => {
    if (f.type !== FilterType.date && f.type !== FilterType.datetime) {
      res[f.field] = buildAgGridFilter(f);
    } else {
      res[f.field] = buildAgGridDateFilter(f);
    }

    return res;
  }, filterModel);
}

export function getAgGridFilterType(filterType: FilterType) {
  switch (filterType) {
    case FilterType.date:
    case FilterType.datetime:
      return 'date';
    case FilterType.number:
      return 'number';
    case FilterType.boolean:
      return 'text';
    default:
      return 'text';
  }
}

export function getDefaultOperator(type: FilterType) {
  if (type === FilterType.text) {
    return FilterOperator.contains;
  } else if (type === FilterType.date || type === FilterType.datetime) {
    return FilterOperator.equals;
  } else if (type === FilterType.number) {
    return FilterOperator.equals;
  } else if (type === FilterType.boolean) {
    return FilterOperator.equals;
  } else if (type === FilterType.enum) {
    return FilterOperator.equals;
  } else if (type === FilterType.autocomplete) {
    return FilterOperator.equals;
  }

  return FilterOperator.contains;
}

export function buildAgGridFilter(f: {
  type: FilterType;
  operator: FilterOperator;
  value: any[];
}): AgGridFilter {
  const obj = {
    type: FilterOperator[f.operator],
    filterType: getAgGridFilterType(f.type),
    filter: f.value.length ? f.value[0] : undefined,
    filterTo: f.value.length === 2 ? f.value[1] : undefined,
  } as AgGridFilter;

  if (obj.filterTo == null) {
    delete obj.filterTo;
  }
  return obj;
}

export function buildAgGridBooleanFilter(f: {
  type: FilterType;
  operator: FilterOperator;
  value: any[];
}): AgGridFilter {
  const obj = {
    type: f.value[0],
    filterType: 'text',
  };

  return obj;
}

export function buildAgGridDateFilter(f: {
  type: FilterType;
  operator: FilterOperator;
  value: any[];
}): AgGridFilter {
  const obj = {
    type: FilterOperator[f.operator],
    filterType: getAgGridFilterType(f.type),
    dateFrom: f.value.length ? f.value[0] : undefined,
    dateTo: f.value.length === 2 ? f.value[1] : undefined,
  } as AgGridFilter;

  if (obj.dateTo == null) {
    obj.dateTo = null;
  }
  return obj;
}

export function mapFromFilterModel(
  filterModel?: AgGridFilterModel,
  fields?: EntityListField[]
) {
  if (!filterModel) {
    return [];
  }
  const filters: FilterValue[] = [];
  Object.keys(filterModel).forEach((key) => {
    const gridFilter = filterModel[key];
    const f = {
      field: key,
      operator: (<any>FilterOperator)[gridFilter.type],
      type:
        fields?.find((f) => f.field === key)?.filterType ??
        (<any>FilterType)[gridFilter.filterType],
      value: [],
    } as FilterValue;

    if (f.type !== FilterType.date && f.type !== FilterType.datetime) {
      if (gridFilter.filter != null) {
        f.value.push(gridFilter.filter);
      }

      if (gridFilter.filterTo != null) {
        f.value.push(gridFilter.filterTo);
      }
    } else {
      if (gridFilter.dateFrom != null) {
        f.value.push(gridFilter.dateFrom);
      }

      if (gridFilter.dateTo != null) {
        f.value.push(gridFilter.dateTo);
      }
    }

    filters.push(f);
  });
  return filters;
}

export function mapFromColumnState(columnState?: ColumnState[]): {
  [field: string]: 'asc' | 'desc';
} {
  if (!columnState) return {};
  return columnState
    .filter((cs) => !!cs.sort && !!cs.colId)
    .reduce((acc: { [key: string]: any }, item) => {
      const colId = item.colId as string;
      const sort = item.sort as string;
      return { ...acc, [colId]: sort.toUpperCase() };
    }, {});
}

export function equalFilters(
  gridFilterModel: AgGridFilterModel | undefined,
  filters: FilterValue[]
) {
  const filtersMapped = mapToFilterModel(filters);

  if (!isEqual(filtersMapped, gridFilterModel)) {
    return false;
  }
  return true;
}

export function getGridColumnType(type: FilterType, fieldType?: FieldType) {
  switch (type) {
    case FilterType.datetime:
      return 'datetimeColumn';
    case FilterType.date:
      return 'dateColumn';
    case FilterType.number:
      if (fieldType === FieldType.currency) return 'currencyColumn';
      if (fieldType === FieldType.percent) return 'percentColumn';
      return 'numberColumn';
    case FilterType.boolean:
      return 'boolColumn';
    case FilterType.enum:
      return 'enumColumn';
    default:
      return ''; // text dà errore in console
  }
}

export function getEventPath(event: Event): HTMLElement[] {
  const path: HTMLElement[] = [];
  let currentTarget: any = event.target;
  while (currentTarget) {
    path.push(currentTarget);
    currentTarget = currentTarget.parentElement;
  }
  return path;
}
export function getAllFocusableElementsOf(el: HTMLElement) {
  return Array.from<HTMLElement>(
    el.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((focusableEl) => {
    return focusableEl.tabIndex !== -1;
  });
}

export function getGridColumns(
  gridColumns: EntityListField[],
  visibleColumns: string[],
  excludeColumns: string[],
  prefix = ''
) {
  return gridColumns
    .filter((gc) => !excludeColumns.find((c) => c === gc.field))
    .map(
      (f) =>
        ({
          colId: f.field,
          field: `${prefix && prefix + '.'}${f.field}`,
          initialHide: !visibleColumns.find((c) => c === f.field),
          headerName: f.elementLabel,
          headerClass: `ag-${
            contentAlignmentMap[f.contentAlignment || ContentAlignmentEnum.Left]
          }-aligned-header`,
          type: getGridColumnType(f.filterType, f.fieldType),
          filterParams: { suppressAndOrCondition: true },
          cellStyle: {
            'text-align':
              contentAlignmentMap[
                f.contentAlignment || ContentAlignmentEnum.Left
              ],
          },
          cellRendererParams: {
            elementValues: f.elementValues,
            precision: f.precision,
          },

          sortable: f.sortable,
          floatingFilter: f.sortable === false ? false : undefined,
          filter: f.sortable === false ? false : undefined,
          sort: f.sort,
          maxWidth: f.maxWidth,
          minWidth:
            f.minWidth ??
            (f.filterType === FilterType.date ||
            f.filterType === FilterType.datetime
              ? 240
              : 200),
          valueGetter: f.valueGetter ? f.valueGetter : undefined,
          // colId: prefix ? `${prefix}.${f.field}` : f.field,
          // initialHide: !visibleColumns.find((c) => c === f.field),
          // field: prefix ? `${prefix}.${f.field}` : f.field,
          // cellRendererParams: {
          //   elementValues: f.elementValues,
          //   precision: f.precision,
          // },
          // headerName: f.elementLabel,
          // type: getGridColumnType(f.filterType, f.fieldType),
          // minWidth: f.minWidth ?? 200,
          // maxWidth: f.maxWidth,
          // sortable: f.sortable,
          // sort: f.sort,
        } as ColDef)
    );
}

export const getExcelFields = (
  fields: EntityListField[],
  columnState: ColumnState[],
  excludeColumns: string[] = []
) => {
  const sortedFields = columnState
    .filter((c) => !c.hide)
    .map((c) => fields.find((f) => c.colId === f.field))
    .filter((c) => c != null) as EntityListField[];

  return sortedFields
    .map((f) => {
      return {
        name: f.field,
        label: f.elementLabel,
        type: f.fieldType,
        elementValues: f.elementValues,
        hidden: f.exportHideColumn,
      } as FieldConfig;
    })
    .filter((gc) => !excludeColumns.find((c) => c === gc.name));
};

// export function tabDownloadExcel<T>(
//   sharedFacade: SharedEntityFacadeInterface<T>,
//   gridColumns: EntityListField[],
//   excludeColumns: string[],
//   columnState: ColumnState[],
//   fileName: string,
//   filters: FilterValue[],
//   limitedRelations?: string[]
// ) {
//   const columns = getExcelFields(gridColumns, columnState, excludeColumns);
//   const sort = mapFromColumnState(columnState);
//   sharedFacade.downloadExcel(
//     fileName,
//     columns,
//     filters,
//     sort,
//     limitedRelations
//   );
// }

// Oggetto di mapping usato per la proprietà cellStyle di AG Grid
const contentAlignmentMap = {
  [ContentAlignmentEnum.Left]: 'left',
  [ContentAlignmentEnum.Center]: 'center',
  [ContentAlignmentEnum.Right]: 'right',
};
