import { InjectionToken } from '@angular/core';

export interface ToastServiceInterface {
  errorFeedback(title: string, message?: string, autoDismiss?: boolean): void;

  successFeedback(title: string, message?: string, autoDismiss?: boolean): void;

  warnNotification(
    title: string,
    message?: string,
    autoDismiss?: boolean
  ): void;

  errorNotification(
    title: string,
    message?: string,
    autoDismiss?: boolean
  ): void;
}

export const TOAST_SERVICE_TOKEN = new InjectionToken<ToastServiceInterface>(
  'ToastService'
);
