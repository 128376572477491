import { Subject } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectionToken, Signal } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BaseDto } from '@do/common-dto';
import { FilterValue } from '@do/common-interfaces';

import { BreadcrumbItem } from './breadcrumb-item';

export interface RouterFacadeInterface {
  notifySelectedItemToOpener(item: BaseDto): Promise<void>;
  notifySavedItemToOpener(item: BaseDto): Promise<void>;
  notifyDeletedItemToOpener(id: string): Promise<void>;
  notifyBreadcrumbChangedToOpener(breadcrumb: BreadcrumbItem[]): Promise<void>;
  addNewEntity(
    entity: string,
    queryParams?: string,
    customTitle?: string
  ): Promise<any>;
  openEntityDetail(
    entity: string,
    id: string,
    edit: boolean,
    queryParams?: string,
    customTitle?: string
  ): Promise<any>;
  searchEntity(entity: string, filters?: FilterValue[]): Promise<any>;
  openEntityList<Tparam>(
    entity: string,
    param: Partial<Tparam>,
    filters?: FilterValue[],
    title?: string,
    fullscreen?: boolean
  ): Promise<void>;

  canViewEntity(entityName: string): boolean;
  canAddEntity(entityName: string): boolean;
  canDeleteEntity(entityName: string): boolean;
  canEditEntity(entityName: string): boolean;

  // isFindMode(): boolean;
  // isEditMode(): boolean;
  // isAddMode(): boolean;
  // isModalListMode(): boolean;
  // isViewOnlyMode(): boolean;

  isFindMode: Signal<boolean>;
  isEditMode: Signal<boolean>;

  // isAddMode: Signal<boolean>;
  isModalListMode: Signal<boolean>;
  isViewOnlyMode: Signal<boolean>;
  channelId: Signal<string | undefined>;
  queryParams: Signal<Params>;
  params: Signal<Params>;
  url: Signal<string>;
  reloadIframe: Subject<void>;

  navigateRelative(
    commands: any[],
    replace?: boolean,
    relativeTo?: ActivatedRoute | null
  ): Promise<boolean>;

  back(): void;
  // readonly router: Router;
  // getCurrentRoute(): ActivatedRoute;
}

export const ROUTER_FACADE_TOKEN = new InjectionToken<RouterFacadeInterface>(
  'RouterFacade'
);
