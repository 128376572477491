import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: `
    <button
      class="btn d-flex align-items-center position-relative"
      [type]="type"
      [class]="cssClasses"
      [title]="title"
      [disabled]="disabled"
      (click)="clicked.emit($event)"
    >
      <span class="material-symbols-outlined" [style.fontSize]="iconFontSize">
        {{ icon }}
      </span>
      <span
        *ngIf="showBadge"
        class="position-absolute top-0 start-0 translate-middle badge rounded-pill"
        [class]="badgeClass"
      >
        {{ badgeContent }}
      </span>
    </button>
  `,
  styleUrls: ['./icon-button.component.scss'],
  selector: 'do-icon-button',
  standalone: true,
  imports: [CommonModule],
})
export class IconButtonComponent {
  @Input()
  cssClasses? = '';

  @Input()
  type = 'button';

  @Input()
  icon = '';

  @Input()
  disabled? = false;

  @Input()
  title = '';

  @Input()
  iconFontSize = '24px';

  @Input()
  badgeContent?: any;

  @Input()
  badgeClass = 'bg-danger';

  @Output()
  clicked = new EventEmitter<Event>();

  get showBadge() {
    return !!this.badgeContent;
  }
}
