import { RoleEntity } from '@do/backend-entities';
import { Precision } from '@do/common-interfaces';
import { marker } from '@do/ngx-translate-extract-marker';

import { RoleCode } from './entity-constants';

export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};
export function sleep(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}
export const kebabize = (str: string) =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
  );

export const camelize = (s: string) =>
  s.replace(/-./g, (x) => x[1].toUpperCase());

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})/;

export const canViewAll = (role: RoleEntity | undefined): boolean =>
  role?.code == RoleCode.SuperAdmin || role?.code == RoleCode.Admin;

export const hasPermission = (
  permission: string,
  entity: string,
  permissions?: string[]
) => {
  const entityName = kebabize(entity.replace('Entity', '')).replace(/-/gi, '_');

  if (!permissions) {
    return false;
  }

  if (permissions?.indexOf(`${permission}_all`) > -1) {
    return true;
  } else {
    return permissions.indexOf(`${permission}_${entityName}_entity`) > -1;
  }
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const decimalFormatter = (precision: Precision): Intl.NumberFormat =>
  new Intl.NumberFormat('it-IT', {
    style: 'decimal',
    minimumFractionDigits: precision.minimumFractionDigits,
    maximumFractionDigits: precision.maximumFractionDigits,
  });

export const currencyFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});

export const isImage = (mimeType: string) => {
  return mimeType?.startsWith('image') || false;
};

export const getDescendantProp = (obj: any, path: string) => {
  const arr = path.split('.');
  while (arr.length && (obj = obj[arr.shift() as string]));
  return obj;
};

export const genCharArray = (charFrom: string, charTo: string) => {
  const a: string[] = [];
  let i = charFrom.charCodeAt(0);
  for (; i <= charTo.charCodeAt(0); ++i) {
    a.push(String.fromCharCode(i));
  }
  return a;
};

export const getErrorDescription = (byteCode: number | null) => {
  if (!byteCode) {
    return '';
  }

  /*
  0	No Error
  1	Switch
  2	Seat Number
  3	Aisle Light 1
  4	Aisle Light 2
  5	Aisle Light 3
  7	Table Light
  8	Under Chair LED
  9	Motor 1
  10	Motor 2
  11	Motor 3
  12	Heating Pad
  13	Occupancy Sensor
  14	Air Massage
  15	Generic
  */
  const peripheralCode = Math.floor(byteCode / 16);

  let peripheral = '';
  if (peripheralCode === 0) {
    return '';
  } else if (peripheralCode === 1) {
    peripheral = marker('Switch');
  } else if (peripheralCode === 2) {
    peripheral = marker('Seat number');
  } else if (peripheralCode === 3) {
    peripheral = marker('Aisle light 1');
  } else if (peripheralCode === 4) {
    peripheral = marker('Aisle light 2');
  } else if (peripheralCode === 5) {
    peripheral = marker('Aisle light 3');
  } else if (peripheralCode === 7) {
    peripheral = marker('Table light');
  } else if (peripheralCode === 8) {
    peripheral = marker('Under chair LED');
  } else if (peripheralCode === 9) {
    peripheral = marker('Motor 1');
  } else if (peripheralCode === 10) {
    peripheral = marker('Motor 2');
  } else if (peripheralCode === 11) {
    peripheral = marker('Motor 3');
  } else if (peripheralCode === 12) {
    peripheral = marker('Heating pad');
  } else if (peripheralCode === 13) {
    peripheral = marker('Occupancy sensor');
  } else if (peripheralCode === 14) {
    peripheral = marker('Air massage');
  } else if (peripheralCode === 15) {
    peripheral = marker('Generic');
  } else {
    peripheral = marker('Unknown error');
  }

  /*
  0	No Error
  1	Not connected
  2	Wrong consumption
  3	Current Overload
  4	Button stucked
  */
  const errorCode = byteCode % 16;

  let error = '';
  if (errorCode === 0) {
    return '';
  } else if (errorCode === 1) {
    error = marker('Not connected');
  } else if (errorCode === 2) {
    error = marker('Wrong consumption');
  } else if (errorCode === 3) {
    error = marker('Current overload');
  } else if (errorCode === 4) {
    error = marker('Button stucked');
  } else {
    error = marker('Unknown error');
  }

  return `${peripheral} ${error.toLowerCase()}`;
};
