import { computed } from '@angular/core';
import { signalStore, withComputed, withState } from '@ngrx/signals';

import { withStoreMethods } from './loader.methods';

export interface LoaderState {
  active: number;
  actionsInProgress: string[];
  customLoadingMessages: string[];
}

const initialState: LoaderState = {
  active: 0,
  actionsInProgress: [],
  customLoadingMessages: [],
};

export const LoaderStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  // computed selector
  withComputed((state) => ({
    isLoading: computed(() => state.active() > 0),
  })),
  // actions & effects
  withStoreMethods()
);
